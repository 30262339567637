import React from 'react'
import Assessment from './views/assesment/Assessments/Assesment'
import Groups from './views/group/Groups'
//import QueryInterface from './views/assesment/queryInsights/ChatInterface'
import DataCollectorComponent from './views/table/DataCollector'
import WizardComponent from './views/table/Wizard'
import Registration from './views/table/registration'
import viewDataComponent from './views/table/viewData'
import assesmentPage from './views/assesment/AssesmentPage'
import dashboardComponent from './views/table/dashboard'
import { Redirect } from 'react-router-dom';
const Dashboard = React.lazy(() => import('./views/assesment/dashboard/reports'))
const QueryInterface = React.lazy(() => import('./views/assesment/queryInsights/queryInsights'))

const routes = [
  { path: '/', exact: true, name: 'Home', isPrivate: false},
  { path: '/dashboard/:id', name: 'dashboard', element: Dashboard, isPrivate: true },
  { path: '/assessments', name: 'assessments', element: Assessment, isPrivate: true },
  //{ path: '/query', name: 'query', element: QueryInterface, isPrivate: true },
  {path: '/query/:id', name: 'chat', element: QueryInterface, isPrivate: true },
  { path: '/projects', name: 'Projects', element: DataCollectorComponent, isPrivate: true },
  { path: '/wizard/:projectId', name: 'Wizard', element: WizardComponent, isPrivate: true },
  { path: '/viewData/:projectId', name: 'viewData', element: viewDataComponent, isPrivate: true},
  { path: '/summary/:projectId/:projectName', name: 'dashboard', element: dashboardComponent, isPrivate: true},
  { path: '/assesmentPage/:assessmentId', name: 'assesmentPage', element: assesmentPage, isPrivate: true},
  { path: '/groups', name: 'Groups', element: Groups, isPrivate: true }
]

export default routes