import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import config from "src/config/config";
import { serverErrors } from "./constants";
import { successHandler } from "./responseHandlers";
import keycloak from "src/Keycloak";

const getAxiosInstance = (baseUrl, client) => {
  let axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      "Trace-Id": uuidv4(),
      "Content-Type": "application/json",
      offset: -330
    },

  });
  return axiosInstance;
 
};

export const handleError = (error) => {
  console.log(`API error:  ${JSON.stringify(error)}`);
  let errorMessage = serverErrors.SERVER_ERROR;
  if (error?.message && error?.message === "Network Error") {
    console.log(`Network Error. Error: ${JSON.stringify(error)}`);
   
  }
  if (error?.response?.status >= 500) {
    errorMessage = serverErrors.SERVER_ERROR;
 
  }
  if (error?.response?.status >= 400 && error?.response?.status < 500) {
    errorMessage = error?.response?.data?.error || error?.response?.data?.message;
  
  }
  if (error?.response) {
    const errorMsg = error?.response?.data?.error || error?.response?.data?.message;
    if (errorMsg) {
      errorMessage = errorMsg;
    }
  } else {
    console.log(`Don't know the error. Error: ${JSON.stringify(error)}`);
  }
  return Promise.resolve(errorMessage);
};

class AsaApiClient {
 client;
  constructor(baseUrl) {
    this.client = getAxiosInstance(baseUrl, this);
  }
async request(config) {
    try {
      let response = await this.client.request(config); 
      let handledResponse = await successHandler(response);
      return Promise.resolve(handledResponse);
    } catch (error) {
      let handledError = await handleError(error);
      return Promise.reject(handledError);
    }
  }
   async get(url, params) {
    return this.request({ method: "GET", url, params });
  }
   async put(url, data, params) {
    return this.request({ method: "PUT", url, data, params });
  }
   async post(url, data, params) {
    return this.request({ method: "POST", url, data, params });
  }
   async delete(url, data, params) {
    return this.request({ method: "DELETE", url, data, params });
  }
   async patch(url, data, params) {
    return this.request({ method: "PATCH", url, data, params });
  }
  async upload(url, formData) {
    try {
      let response = await this.client.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      let handledError = await handleError(error);
      return Promise.reject(handledError);
    }
  }
}

const baseURL = config.apiUrl;
const asaApiClient = new AsaApiClient(baseURL);
export { asaApiClient as asaApiClient };
