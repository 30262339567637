//Don't remove the commented code.

export const projectDetailsEndPoints = {
    createProject: () => `/project/create/projectDetails`,
    updateProject: (projectId) => `/project/update/projectDetails/${projectId}`,
    // getProjectDetails: () => `/project/get/projectDetails`,
    getProjectDetailsById: (projectId) => `/project/get/projectDetails/${projectId}`,
    getProjectSummary: (projectId) => `/project/get/projectSummary/${projectId}`,
    getQuestionsSummary: (projectId) => `/project/get/questionsSummary/${projectId}`,
    getProjectDetailsByUserId: (owner) => `/project/get/userProjectDetails/${owner}`,
    getDeployedProjectDetails: (owner) => `/project/get/deployedProjectDetails/${owner}`,
    getQrCode: () => `/project/get/signedurl`,
    // getAssesmentFilterData: (projectId) => `/project/get/questionsSummary/${projectId}?filter=true`,
    deleteProject: (projectId) => `/project/delete/${projectId}`
}

export const userDetailsEndPoints = {
    createUsers: (projectId) => `/user/create/users/${projectId}`,
    getUserDetails: (projectId) => `/user/get/users/projectId/${projectId}`
}


// export const questionDetailsEndPoints = {
//     getQuestions: (questionId) => `/project/get/questionDetails/${questionId}`
// }

export const responseDetailsEndPoints = {
    getResponseDetals: (projectId, questionId) => `/response/get/responseDetails/${projectId}?question_id=${questionId}`,
} 

export const assesmentDetailsEndPoints = {
    createAssessment: () => '/assessment/create/assessmentDetails',
    getAssessmentDetails: (owner) => `/assessment/assessment-details/${owner}`,
    getAssessmentDetailsById: (assessmentId) => `/assessment/assessment-details-by-id/${assessmentId}`,
    getUserResponsesById: (projectId) => `/assessment/non-curated/${projectId}`,
    createCurateResponse: (projectId) => `/assessment/curate/${projectId}`,
    getCuratedResponses: (projectId) => `/assessment/curate/${projectId}`,
    createDiscardedResponse: (projectId) => `/assessment/discard/${projectId}`,
    getDiscardedResponses: (projectId) => `/assessment/discard/${projectId}`,
    uploadFiles: () => `/assessment/uplaod`,
    deleteFiles: () => `/assessment/delete`,
    updateAssessmentDetails: (assessment_id) => `/assessment/update/assessmentDetails/${assessment_id}`
}

export const queryInsightsEndPoints = {

    postCustomQuestion: () =>'/reports/saveQuestion',
    getCustomQuestions: (assessment_id, user_id) => `/reports/getReport?assessment_id=${assessment_id}&user_id=${user_id}`,
    deleteCustomQuestion: () =>'/reports/delete'

}
export const reportsEndPoints = {

    getSurveyQuestion: () =>'/reports/surveyQuestions',
    

}


export const groupEndPoints = {
    getQrCodeList: () => '/groups/qr-list',
    getGroupList: () => '/groups/list',
    generateQrCode: () => '/groups/generate-qr'
};
